import { AnimatePresence } from 'framer-motion'
import { useMemo, useRef, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import useRegion from '../../../hooks/use-region'
import { usePanToItem } from '../../../services/map/MarkerCollection'
import { useFilterState } from '../../../stores/MapFiltersState'
import { HolidayJourney } from '../../../types/features'
import {
  Delay,
  DetailPanelType,
  GeojsonFeature,
  Highway,
  Region,
  TimSign,
  TrafficCamera,
  VmsSign,
} from '../../../types/JourneyPlannerTypes'
import { ConditionItemProps } from '../../common/condition-card'
import { ConditionCard } from '../../common/condition-card/index'
import { PANEL_COMPONENT_MAP } from '../../common/pop-out-panel'
import PopOutPanelContent from '../../common/pop-out-panel/content'
import PanelSection from '../../layout/panel-section'
import PanelSubNavigation from '../../navigation/navigation-panel/panel-sub-navigation'
import { Portal } from '../../portal'

type HighwayListParams = {
  regionSlug: string
  type?: string
  index?: string
}

export default function HighwayList() {
  const { regionSlug, type, index } = useParams<HighwayListParams>()
  const mapFilters = useFilterState().get()

  const region = useRegion({ panOnLoad: true })
  const regionGeneralWarning = region?.properties['general-warnings']
  const regionHighways = region?.properties.highways

  const history = useHistory()
  const isTabletPortraitUp = window.innerWidth > 600
  const baseUrl = `/highway-conditions/${regionSlug}`

  let itemId = index ? parseInt(index) : undefined
  usePanToItem({ type: type, id: itemId, focus: true })

  // Get selected item and itemName from sidepanel, and next/prev item of that type:
  const [item, itemName] = useMemo(() => {
    const itemNameKeys = ['name', 'Name', 'Title']
    let item: any
    let itemName

    if (region && index && type) {
      const itemsByType = region?.properties[type as keyof Region]
      if (itemsByType) {
        const items = Object.values(itemsByType)
        item = items.find((i) => {
          return i.properties.id === itemId
        })
        if (item) {
          itemNameKeys.forEach((key) => {
            if (item.properties[key]) {
              itemName = type === 'tts' || type === 'vms' ? 'Travel Information' : item.properties?.[key]
            }
          })
        }
      }
    }
    return [item, itemName]
  }, [type, index, itemId, region])

  // Set prevItem / nextItem for footer links in popout:
  const [prevItem, nextItem] = useMemo(() => {
    let prevItem, nextItem
    if (item) {
      const itemsByType = region?.properties[type as keyof Region]
      let itemGroup

      if (itemsByType && itemId) {
        const items = Object.values(itemsByType)
        itemGroup = items.filter((i) => {
          return i.properties.TasJourneyId === item.properties.TasJourneyId
        })

        const itemIndex = itemGroup.findIndex((i) => i.properties.id === itemId)
        const lastIndex = itemGroup.length - 1
        const nextIndex = itemIndex === lastIndex ? 0 : itemIndex + 1
        const prevIndex = itemIndex === 0 ? lastIndex : itemIndex - 1

        if (itemGroup.length > 1 && itemIndex > -1) {
          nextItem = itemGroup[nextIndex].properties.id
          prevItem = itemGroup[prevIndex].properties.id
        }
      }
    }
    return [prevItem, nextItem, region]
  }, [item, type, itemId, region])

  // Return null if no prev/nextItem:
  let itemLinks =
    prevItem && nextItem
      ? { prevUrl: `${baseUrl}/${type}/${prevItem}`, nextUrl: `${baseUrl}/${type}/${nextItem}` }
      : null

  const getGeneralWarningRows = (regionGeneralWarning: GeojsonFeature<Delay>[]): ConditionItemProps[] => {
    const rows: ConditionItemProps[] = []
    const type: DetailPanelType = 'general-warnings'

    const { label, icon } = mapFilters[type]
    rows.push({
      icon,
      iconLabel: label,
      label: (regionGeneralWarning.length || 0) + ' ' + label,
      urls: regionGeneralWarning.map((gw) => {
        return {
          title: gw.properties.Name || '',
          url: `/highway-conditions/${regionSlug}/${gw.properties.type}/${gw.properties.id}`,
        }
      }),
    })

    return rows
  }

  const getSummaryForHighway = (highway: GeojsonFeature<Highway>): ConditionItemProps[] => {
    const rows: ConditionItemProps[] = []
    const delayFilterTypes: DetailPanelType[] = [
      'closures',
      'roadworks',
      'hazards',
      'warnings',
      'cameras',
      'ev',
      'tts',
      'vms',
      'holiday-journeys',
    ]
    delayFilterTypes
      .filter((type) => {
        // Respect enabled map filters
        const { enabled, applicable } = mapFilters[type]
        return applicable && enabled && region?.properties[type]?.length
      })
      .forEach((type) => {
        const itemsForHighway:
          | GeojsonFeature<TrafficCamera | Delay | TimSign | VmsSign | HolidayJourney>[]
          | undefined = region?.properties[type]?.filter(
          (item: GeojsonFeature<TrafficCamera | Delay | TimSign | VmsSign | HolidayJourney>) => {
            if (item.properties.type === 'holiday-journeys') {
              let hwName = item.properties.Title.split(' ')[0]
              return hwName === highway.properties.name
            }
            if (item.properties.type === 'tts') {
              return item.properties?.journey?.id === highway.properties?.id
            }

            if (item.properties.type === 'vms' && item.properties?.ClassName !== 'ChchVms') {
              return (
                item.properties.journeys &&
                item.properties.journeys.length > 0 &&
                item.properties.journeys[0].name === highway.properties.name
              )
            } else return item?.properties?.TasJourneyId === highway.properties.id
          },
        )
        if (itemsForHighway?.length) {
          const { label, icon } = mapFilters[type]
          rows.push({
            icon,
            iconLabel: label,
            label: (itemsForHighway.length || 0) + ' ' + label,
            urls: itemsForHighway.map((item) => {
              if (item.properties.type === 'holiday-journeys') {
                return {
                  title: item.properties.Title || '',
                  url: `/highway-conditions/${regionSlug}/${item.properties.type}/${item.properties.id}`,
                }
              }
              if (item.properties.type === 'tts' || item.properties.type === 'vms') {
                return {
                  title: item.properties.name || '',
                  url: `/highway-conditions/${regionSlug}/${item.properties.type}/${item.properties.id}`,
                }
              } else
                return {
                  title: item.properties.Name || '',
                  url: `/highway-conditions/${regionSlug}/${item.properties.type}/${item.properties.id}`,
                }
            }),
          })
        }
      })

    return rows
  }

  // Popout components for each item type:
  const DetailPanelComponent = PANEL_COMPONENT_MAP[type as DetailPanelType]
  const refTitle = useRef(null)
  useEffect(() => {
    if (item) refTitle.current.focus()
  }, [item])

  // Shift keyboard focus on the first highway in the list
  useEffect(() => {
    const firstHighway = document.getElementsByClassName('c-condition-card__label')[0]
    if (firstHighway) firstHighway.focus()
  }, [regionSlug])

  return (
    <>
      <PanelSubNavigation
        title={`Highway conditions for ${region?.properties.name}`}
        navLayout='titleBelow'
        prevLabel='Regions'
        prevUrl='/highway-conditions'
        nextUrl={isTabletPortraitUp ? '/highway-conditions/traffic-and-travel-list-view/' : undefined}
        nextLabel={isTabletPortraitUp ? 'Table View' : undefined}
      />

      <PanelSection navigation={true}>
        <ul className='l-conditions-list'>
          {regionGeneralWarning && regionGeneralWarning.length ? (
            <ConditionCard
              key={regionGeneralWarning.length}
              link={''}
              heading={'General warnings'}
              conditionItems={regionGeneralWarning.length ? getGeneralWarningRows(regionGeneralWarning) : undefined}
              expandable={true}
              smallLinks={true}
            />
          ) : (
            <></>
          )}
          {regionHighways?.map((highway) => {
            const highwaySummary = getSummaryForHighway(highway)
            const highwayLink = `/highway-conditions/${regionSlug}/highway/${highway.properties.id}`
            return (
              <ConditionCard
                key={highway.properties.id}
                link={isTabletPortraitUp ? highwayLink : ''}
                heading={highway.properties.name}
                conditionItems={highwaySummary.length ? highwaySummary : undefined}
                expandable={true}
                smallLinks={true}
              />
            )
          })}
        </ul>
      </PanelSection>

      {/* Popout panel for highway-conditions: */}
      <Portal>
        <AnimatePresence exitBeforeEnter={true}>
          {item && DetailPanelComponent && (
            <PopOutPanelContent
              ref={refTitle}
              key='selected-item'
              title={itemName}
              closeAction={() => {
                history.push(baseUrl)
                const activeLink = document.getElementsByClassName('c-link-list__link--active')[0]
                activeLink.focus()
              }}
            >
              <DetailPanelComponent item={item.properties as never} itemLinks={itemLinks} />
            </PopOutPanelContent>
          )}
        </AnimatePresence>
      </Portal>
    </>
  )
}
